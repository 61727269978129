export const GlobalVariable = {

    SITE_NAME: 'Calmica Admin',
    PATTERNS: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    tokenKey: 'amp reward_token'

};

export const SideBarList = {
    list: [
        {path: 'users', name: 'Users', icon: 'fa fa-users'},

        {path: 'plans', name: 'Plans', icon: 'fa fa-podcast'},

         {path: 'libray', name: 'Libray', icon: 'fa fa-handshake-o'},

        {path: 'order', name: 'Order', icon: 'fa fa-first-order'},

        {path: 'vr', name: 'VR', icon: 'fa fa-yelp'},

        {path: 'profession', name: 'Profession', icon: 'fa fa-linux'},

        {path: 'contact_us', name: 'Contact Us', icon: 'fa fa-commenting'},

        {path: 'about', name: 'About', icon: 'fa fa-commenting'},

        {path: 'policy', name: ' Privacy Policy', icon: 'fa fa-fire'},

        {path: 'terms', name: 'Terms & Conditions', icon: 'fa fa-th'},
        
        // {path: 'libray-video', name: 'Terms & Conditions', icon: 'fa fa-th'},
    ]
};

export const CONSTANT = {
    languages: [{name: 'English', id: 1}, {name: 'Spanish', id: 2}]
};

